import * as React from 'react';
import Layout from 'components/layout/layout';
import AdvancedSearch from 'components/search/advancedSearch';

export default ({ location }: any) => {
    return (
        <Layout location={location} title={'Find a COVID-19 Clinical Trial'} description={'Find a COVID-19 Trial near you. Use the additional filters to refine your search and give you a more accurate result'} tenantCss={'searchCssUrl'}>
            <AdvancedSearch />
        </Layout>
    );
};
