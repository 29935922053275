import { createStyles } from '@material-ui/styles';

export const styles = createStyles({
    dropdownInput: {
        textAlign: 'center',
        paddingRight: '32px',
        position: 'relative',
        color: '#6D7278',
        fontWeight: 400,
    },
    dropdownChevron: {
        position: 'absolute',
        right: '5%',
        top: 'calc(50% - 12px)'
    }
});
