import { createStyles } from "@material-ui/styles";
import { Theme } from "components/common/theme/default";
import { filterStyles } from "components/common/theme/styles";

export const styles = (theme: Theme) =>
  createStyles({
    filterSection: {
      overflow: "hidden",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "15px",
      },
    },
    sectionName: {
      ...filterStyles(theme).sectionName,
    },
    sectionItems: {
      margin: "10px 0",
      width: "100%",
      padding: 0,
    },
    filterItem: {
      textDecoration: "none",
      position: "relative",
      verticalAlign: "top",
      listStyle: "none",
      display: "block",
      width: "100%",
    },
    filterItemLabel: {
      color: theme.color.sectionName,
      paddingLeft: "30px",
      lineHeight: "29px",
      userSelect: "none",
      display: "inline",
      cursor: "pointer",
      fontSize: "16px",
      fontWeight: 400,
    },
    filterItemBox: {
      position: "relative",
      userSelect: "none",
      display: "inline",
      cursor: "pointer",
      height: "21px",
      width: "21px",
      left: 0,
      "& span": {
        border: `1px solid ${theme.color.checkboxBorder}`,
        borderRadius: "5px",
        position: "absolute",
        height: "21px",
        width: "21px",
        left: 0,
        top: 0,
      },
      "& input": {
        position: "absolute",
        cursor: "pointer",
        height: "21px",
        width: "21px",
        opacity: 0,
        "&:checked ~ span": {
          background: "url(/images/checked.svg) center center no-repeat",
          backgroundSize: "14px 14px",
          borderRadius: "5px",
          opacity: 0.75,
        },
      },
    },
  });
