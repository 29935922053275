import { FilterItem, FiltersSection } from 'components/filter/types';
import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { WithStyles, withStyles } from '@material-ui/styles';
import { styles } from 'components/filter/filters.style';
import { GetStudyCodes } from 'components/fetch';
import { mapFilters } from 'components/filter/filtersMapper';
import Loading from 'components/loading/loading';
import { ReactNode } from 'react';
import Checkbox, {
    Props as CheckboxProps,
} from 'components/common/checkbox/checkbox';
import { SearchModel } from 'src/model/searchModel';
import { getPopoverMap, PopoverMap } from 'components/filter/popoverMap';
import { withStrings, WithStrings } from 'components/locale';
import InlineInfo from 'components/common/popover/inlineInfo';

interface Props extends WithStyles<typeof styles>, WithStrings {
    children: ReactNode;
    selectedFilters: SearchModel['filters'];
    onUpdated: (selectedFilters: SearchModel['filters']) => void;
}

interface CheckboxItemModel {
    section: FiltersSection;
    filterItem: FilterItem;
    displayValue: string;
    internalValue: string;
}

class Filters extends React.Component<Props> {
    map: PopoverMap;

    constructor(props: Props, context: any) {
        super(props, context);
        this.map = getPopoverMap(props.strings);
    }

    render = () => {
        const { strings } = this.props;
        return (
            <GetStudyCodes>
                {({ data, error }) => {
                    if (data) {
                        let filters = mapFilters({ data: data.Data, strings: strings.common });
                        return (
                            <>
                                <Row>{this.mapSections(filters)}</Row>
                                {this.props.children}
                            </>
                        );
                    }
                    if (error) {
                        return 'Error fetching codes';
                    }
                    return <Loading color={{ fromTheme: 'background' }} />;
                }}
            </GetStudyCodes>
        );
    };

    private mapSections = (sections: FiltersSection[]) => {
        if (!sections) {
            return null;
        }
        return ( <> {sections.map(this.mapSection)}
        {[{parameterName: "Gender",
                        sectionName: "Sex",
                        sectionKey: "GENDER",
                        items: [{
                            internalValue: "GENDER_ALL",
                            displayValue: "Both (Female and Male)",
                            sortOrder: 0,
                            items: []
                            },
                            {
                            internalValue: "GENDER_MALE~GENDER_ALL",
                            displayValue: "Male",
                            sortOrder: 0,
                            items: []
                        },
                        {
                            internalValue: "GENDER_FEMALE~GENDER_ALL",
                            displayValue: "Female",
                            sortOrder: 0,
                            items: []
                        }]}].map(this.mapSection)} </>)
    };

    private mapSection = (section: FiltersSection, index: number) => {
        const { classes } = this.props;
        const items =
            section.items &&
            section.items.map((item, i) => (
                <li
                    className={classes.filterItem}
                    key={`${section.sectionKey}-${item.internalValue}-${i}`}
                >
                    {this.renderCheckbox(section, item)}
                </li>
            ));
        return (
            section.sectionName !== 'Gender' ? (
            <Col
                className={classes.filterSection}
                key={`${section.sectionKey}-${index}`}
                md={'3'}
            >
                <span className={classes.sectionName}>
                    {section.sectionName}
                    {this.getSectionInfo(section)}
                </span>
                <ul className={classes.sectionItems}>{items}</ul>
            </Col>
            ) : null
        );
    };

    private renderCheckbox = (
        section: FiltersSection,
        filterItem: FilterItem
    ) => {
        const { selectedFilters } = this.props;
        const isSelected =
            section.parameterName in selectedFilters &&
            selectedFilters[section.parameterName]!.indexOf(
                filterItem.internalValue
            ) >= 0;
        const checkboxItem: CheckboxItemModel = {
            section,
            filterItem,
            displayValue: filterItem.displayValue,
            internalValue: filterItem.internalValue,
        };
        return (
            <>
                <Checkbox
                    isChecked={isSelected}
                    item={checkboxItem}
                    parameterName={section.parameterName}
                    onChanged={this.onChanged}
                    inlineInfo={this.getCheckboxInfo(section, filterItem)}
                />
            </>
        );
    };

    private getSectionInfo = (section: FiltersSection) => {
        const sectionMap = this.map[section.sectionKey];
        if (!sectionMap || !sectionMap.info) return null;
        return (
            <InlineInfo header={section.sectionName} body={sectionMap.info} />
        );
    };

    private getCheckboxInfo = (
        section: FiltersSection,
        filterItem: FilterItem
    ) => {
        const sectionMap = this.map[section.sectionKey];
        if (!sectionMap || !sectionMap.childCodes) return null;
        const itemMap = sectionMap.childCodes[filterItem.internalValue];
        if (!itemMap || !itemMap.info) return null;
        return (
            <InlineInfo header={filterItem.displayValue} body={itemMap.info} />
        );
    };

    private onChanged = (value: boolean, { item }: CheckboxProps) => {
        const { selectedFilters, onUpdated } = this.props;
        const { section, filterItem } = item as CheckboxItemModel;

        const filters = selectedFilters[section.parameterName] || [];
        const index = filters.indexOf(filterItem.internalValue);

        if (value) {
            if (index >= 0) return;
            selectedFilters[section.parameterName] = [
                ...filters,
                item.internalValue,
            ];
        } else {
            if (index < 0) return;
            filters.splice(index, 1);
            selectedFilters[section.parameterName] = filters;
        }

        onUpdated(selectedFilters);
    };
}

export default withStyles(styles, { index: 3 })(withStrings(Filters));
