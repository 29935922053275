import * as React from 'react';
import { Input } from 'reactstrap';

interface SearchInputProps {
    placeholder: string;
    id?: string;
    value?: string;
    classes?: {
        container?: string;
        input?: string;
    };
    onChange?: (e: any) => void;
}

export class SearchInput extends React.Component<SearchInputProps> {
    render = () => {
        const { placeholder, id, onChange, value } = this.props;
        const classes = this.props.classes || {};
        return (
            <div className={classes.container}>
                <Input
                    id={id}
                    placeholder={placeholder}
                    className={classes.input}
                    onChange={onChange}
                    value={value}
                    autoComplete={'off'}
                    maxLength={150}
                />
            </div>
        );
    };
}
