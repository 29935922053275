import { createStyles } from '@material-ui/styles';
import { Theme } from 'components/common/theme/default';

export const styles = (theme: Theme) => {
    const dropdownClearFix = {
        backgroundColor: theme.color.inputBorder,
        borderColor: theme.color.inputBorder,
        color: theme.color.sectionName,
    }; 
    return createStyles({
        searchContainer: {
            color: theme.color.inputText,
            fontFamily: theme.fontFamily,
            overflow: 'hidden',
            margin: '0 auto',
            minHeight: 'calc(100vh - 150px)',
        },
        header: {
            background: theme.color.background,
            backgroundPosition: 'center, right bottom',
            backgroundSize: 'cover',
            color: theme.color.text,
            paddingTop: '75px',
            '& h1': {
                marginTop: '60px',
                fontWeight: 300,
            },
            '& p': {
                margin: '0',
                paddingBottom: '80px',
            },
            [theme.breakpoints.down('sm')]: {
                '& h1': {
                    fontSize: '32px',
                    lineHeight: '1.2',
                    height: 'auto',
                    marginTop: '20px',
                },
            },
        },
        advancedForm: {
            background: theme.color.advancedBackground,
            paddingTop: '30px',
            '& input': {
                borderColor: theme.color.inputBorder,
                padding: '21px 12px 20px 10px',
                color: theme.color.inputText,
                marginBottom: '30px',
                borderRadius: '6px',
                lineHeight: '22px',
                fontSize: '16px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                [theme.breakpoints.down('md')]: {
                    marginBottom: '20px',
                },
                [theme.breakpoints.down('sm')]: {
                    padding: '12px 10px 12px 17px',
                    lineHeight: '19px',
                    fontSize: '14px',
                    height: 'auto',
                },
            },
            '& label': {
                fontSize: '14px',
                lineHeight: '19px',
                fontWeight: 700,
            },
            '& input::-ms-clear': {
                display: 'none',
            },
        },
        searchButtonsCol: {
            [theme.breakpoints.down('sm')]: {
                marginBottom: '20px',
            },
        },
        distanceInputCol: {
            '& .dropdown-menu': {
                boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
                '& button': {
                    color: '#6D7278',
                    fontWeight: 400,
                    paddingLeft: '10px',
                },
            },
            [theme.breakpoints.down('md')]: {
                marginBottom: '20px',
            },
        },
        filters: {
            background: theme.color.filtersBackground,
            padding: '30px 0',
            '& li > div > label:last-child':{
                paddingRight: '15px',
                "& div": {
                    position: 'absolute',
                },
            },
        },
        searchButtons: {
            textAlign: 'right',
            width: '100%',
            whiteSpace: 'nowrap',
            marginTop: '32px',
            '& a': {
                color: theme.color.inputText,
                marginRight: '40px',
                lineHeight: '22px',
                fontSize: '16px',
                height: '22px',
                '&:hover': {
                    textDecoration: 'underline',
                    cursor: 'pointer',
                },
            },
            '& button': {
                padding: '0.5em 0.6em',
                border: '2px solid #00ACD9',
                color: '#00ACD9',
                backgroundColor: 'transparent',
                minWidth: '110px',
                fontWeight: 600,
                [theme.breakpoints.down('lg')]: {
                    fontSize: '14px',
                },
                [theme.breakpoints.down('sm')]: {
                    padding: '0.6em 0.6em',
                    width: '130px',
                },
                '&:hover': {
                color: 'rgba(255,255,255)',
                backgroundColor: '#00ACD9',
                border: '2px solid #00ACD9',
                }
            },
            [theme.breakpoints.down('sm')]: {
                display: 'flex',
                flexDirection: 'row-reverse',
                alignItems: 'center',
                '& a': {
                    width: '50%',
                    margin: 0,
                    textAlign: 'center',
                },
                '& button': {
                    width: '50% !important',
                },
            },
        },
        dropdown: {
            '& .btn-secondary': {
                ...dropdownClearFix,
                padding: '8px 0 9px 0',
                borderRadius: '6px',
                fontWeight: 'bold',
                minWidth: '122px',
                width: '100%',
                '&:hover': dropdownClearFix,
                '&.dropdown-toggle': {
                    '&:active': dropdownClearFix,
                },
                '&:active': {
                    boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important',
                },
                '&:focus': {
                    boxShadow: '0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important',
                },
                '& > div': {
                    textAlign: 'left',
                    paddingLeft: '10px',
                }
            },
        },
    });
};
