import * as React from 'react';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { IconContext } from 'react-icons/lib/cjs';
import { styles } from 'components/search/searchDropdown.styles';
import { WithStyles, withStyles } from '@material-ui/styles';

interface Props extends WithStyles<typeof styles> {
    placeholder: string;
    className?: string;
    id?: string;
    items?: SearchDropdownItem[];
    value: number;
    onClick?: () => void;
    onChange?: (value: number) => void;
}

interface State {
    dropdownOpen: boolean;
    value: number | string;
    label: string;
}

interface SearchDropdownItem {
    label: string;
    value?: string | number | null;
}

class SearchDropdown extends React.Component<Props, State> {

    dropDownRef = React.createRef<Dropdown>();

    constructor(props: Props) {
        super(props);

        this.state = {
            value: 0,
            dropdownOpen: false,
            label: props.placeholder,
        };
    }

    render = () => {
        const { id, items, className, onClick, placeholder, classes } = this.props;
        const { label, value } = this.state;
        return (
            <Dropdown
                id={id}
                isOpen={this.state.dropdownOpen}
                toggle={this.toggle}
                className={className}
                onClick={onClick}
                ref={this.dropDownRef}
            >
                <DropdownToggle disabled={this.props.value > 0 ? false : true }>
                    <div className={classes.dropdownInput}>
                        {this.props.value > 0 ? `${this.props.value} miles` : placeholder}
                        <div className={classes.dropdownChevron}>
                            <IconContext.Provider value={{ size: '24px' }}>
                                { this.state.dropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown /> }
                            </IconContext.Provider>
                        </div>
                    </div>
                </DropdownToggle>
                {this.createMenu(id, items)}
            </Dropdown>
        );
    };

    private readonly toggle = () =>
        this.setState((prevState: State) => ({
            dropdownOpen: !prevState.dropdownOpen,
        }));

    private onSelect = (evt: any) => {
        const { onChange } = this.props;
        const label = evt.target.innerText;
        const value = parseInt(evt.target.innerText, 10);
        this.setState(() => ({
            value,
            label,
        }));
        if (onChange) {
            onChange(value);
        }
    };

    private createMenu = (id?: string, items?: SearchDropdownItem[]) => {
        if (!items) {
            return null;
        }
        const dropdownItems = items.map((item, index) => (
            <DropdownItem
                data-value={item.value}
                key={`${id}-${index}`}
                onClick={this.onSelect}
            >
                {item.label}
            </DropdownItem>
        ));
        return <DropdownMenu modifiers={{
            computeStyle: {
                fn: (data) => {
                    const width = (this.dropDownRef.current as any).containerRef.current.clientWidth;
                    const newData = data;
                    newData.styles.width = width;
                    newData.styles.maxWidth = width;
                    return newData;
                },
            },

        }}>{dropdownItems}</DropdownMenu>;
    };
}

export default withStyles(styles)(SearchDropdown);
