import { withStyles, WithStyles } from "@material-ui/styles";
import { defaultClassNames } from "components/common/props";
import { styles } from "./advancedSearch.style";
import * as React from "react";
import { Button, Col, Row, Container, Label } from "reactstrap";
import { SearchInput } from "components/common/searchInput";
import SearchDropdown from "components/search/searchDropdown";
import Filters from "components/filter/filters";
import { createRef } from "react";
import { navigate } from "@reach/router";
import { withPrefix } from "gatsby";
import { WithStrings, withStrings } from "components/locale";
import { SearchModel } from "src/model/searchModel";
import { serializeToQuery } from "src/services/querySerializer";
import LocationInput, {
  Location,
} from "components/common/locationInput/locationInput";
import { getFirstSugestion } from "components/common/locationInput/getFirstSugestion";

interface Props extends WithStyles<typeof styles>, WithStrings {
  classNames?: typeof defaultClassNames;
}

interface State {
  keyword: string;
  location: Location;
  selectedFilters: SearchModel["filters"];
  distance: number;
}

const defaultState = (): State => ({
  selectedFilters: {},
  location: {
    lat: 0,
    lng: 0,
    locationName: "",
  },
  distance: 500,
  keyword: "",
});

class AdvancedSearch extends React.Component<Props, State> {
  formRef = createRef<HTMLFormElement>();

  constructor(props: Props) {
    super(props);
    this.state = defaultState();
  }

  render = () => {
    const { classes, strings } = this.props;
    const { selectedFilters, location, distance } = this.state;

    return (
      <div className={classes.searchContainer}>
        <div className={classes.header}>
          <Container>
            <h1>{strings.search.advancedSearch}</h1>
            <p>{strings.search.accurateResult}</p>
          </Container>
        </div>
        <form ref={this.formRef}>
          <div className={classes.advancedForm}>
            <Container>
              <Row>
                <Col md={6} lg={3} xl={4}>
                  <Label for="keyword">
                    {strings.common.keywordsSearchLabel}
                  </Label>
                  <SearchInput
                    id="keyword"
                    placeholder={strings.common.keywordsSearchPlaceHolder}
                    onChange={this.onKeywordChange}
                  />
                </Col>
                <Col md={6} lg={3} xl={3}>
                  <Label for="location">{strings.common.locationLabel}</Label>
                  <LocationInput
                    placeholder={strings.common.locationPlaceHolder}
                    onLocationChanged={this.onLocationChanged}
                    value={location.locationName}
                  />
                </Col>
                <Col md={6} lg={3} xl={2} className={classes.distanceInputCol}>
                  <Label for="distance">{strings.common.distanceLabel}</Label>
                  <SearchDropdown
                    id="distance"
                    placeholder={strings.search.distanceLabel}
                    className={classes.dropdown}
                    items={this.getRadiusItems()}
                    onChange={this.onDistanceChange}
                    value={location.locationName ? distance : 0}
                  />
                </Col>
                <Col md={6} lg={3} xl={3} className={classes.searchButtonsCol}>
                  {this.createButtons()}
                </Col>
              </Row>
            </Container>
          </div>
          <div className={classes.filters}>
            <Container>
              <Filters
                selectedFilters={selectedFilters}
                onUpdated={this.onFiltersUpdated}
              >
                <Row>
                  <Col>{this.createButtons()}</Col>
                </Row>
              </Filters>
            </Container>
          </div>
        </form>
      </div>
    );
  };

  private onLocationChanged = (location: Location) => {
    this.onDistanceChange(this.state.distance);
    this.setState({
      location,
    });
  };

  private onSubmit = (e: any) => {
    e.preventDefault();
    if (typeof window === "undefined") {
      return;
    }
    const { location, distance, keyword, selectedFilters } = this.state;
    let model: SearchModel = {
      distance,
      keywords: keyword,
      filters: selectedFilters,
    };

    if (location.locationName) {
      model = { ...model, ...location };
    }

    if (location.locationName) {
      if (!location.lat && !location.lng) {
        getFirstSugestion()
          .then((location: Object) => {
            model = { ...model, ...location };
            console.log("HERE", model);
            const searchUrl = withPrefix("/search-results/");
            console.log(searchUrl);
            navigate(`${searchUrl}?${serializeToQuery(model)}`);
          })
          .catch((err) => {
            console.log("HERE_ERR", err);
          });
      } else {
        model = { ...model, ...location };
        const searchUrl = withPrefix("/search-results/");
        navigate(`${searchUrl}?${serializeToQuery(model)}`);
      }
    } else {
      const searchUrl = withPrefix("/search-results/");
      navigate(`${searchUrl}?${serializeToQuery(model)}`);
    }
  };

  private onFiltersUpdated = (selectedFilters: SearchModel["filters"]) =>
    this.setState({ selectedFilters });

  private onKeywordChange = (e: any) => {
    e.preventDefault();
    if (e.currentTarget) {
      this.setState({
        keyword: e.currentTarget.value,
      });
    }
  };

  private onClear = (e: any) => {
    e.preventDefault();
    this.setState(defaultState());
    if (this.formRef.current) {
      this.formRef.current.reset();
    }
  };

  private onDistanceChange = (value: number) => {
    this.setState({
      distance: value,
    });
  };

  private createButtons = () => {
    const { classes, strings } = this.props;
    return (
      <div className={classes.searchButtons}>
        <a tabIndex={0} role="button" onClick={this.onClear}>
          {strings.common.clearAll}
        </a>
        <Button onClick={this.onSubmit}>{strings.search.searchLabel}</Button>
      </div>
    );
  };

  private getRadiusItems = () => {
    const { strings, format } = this.props;
    return [25, 50, 100, 500, 1000].map((distance) => ({
      label: format(strings.common.distanceValue, { distance }),
    }));
  };
}

export default withStyles(styles, { index: 2 })(withStrings(AdvancedSearch));
