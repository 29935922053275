import { LocaleStrings } from 'components/locale/strings';

export interface PopoverMap {
    [code: string]: {
        info?: string;
        childCodes?: PopoverMap;
    };
}

export function getPopoverMap({ studyDetails, search }: LocaleStrings): PopoverMap {
    return {
        PHASE: {
            info: studyDetails.trialSummary.studyPhaseInfo,
        },
        RECSTATUS: {
            childCodes: {
                RECSTATUS_RECRUITING: {
                    info: search.filter.recruitingInfo,
                },
                RECSTATUS_NOT_YET_RECRUITING: {
                    info: search.filter.notRecruitingInfo,
                },
                RECSTATUS_STUDY_COMPLETED: {
                    info: search.filter.studyCompletedInfo,
                },
                RECSTATUS_NOT_RECRUITING: {
                    info: search.filter.terminatedWithdrawnInfo,
                },
            },
        },
    };
}
